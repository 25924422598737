import styled from "styled-components";
import {colors} from "../../styles/colors";
import XcomCross from './x_curves.svg';

export const TextSlideContainer = styled.div`
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 10vh solid ${colors.primary};
    box-sizing: border-box;
    background-image: url(${XcomCross});
    background-position: 70vw center;
    background-repeat: no-repeat;
`

export const TextSlide = styled.div`
    color: ${colors.black};
    width: 70vw;
`

export const Logo = styled.div`
    position: absolute;
    left: 5vh;
    top: 5vh;
    z-index: 0;
    
    img {
        max-width: 100px
    }
`