import qs from 'qs'
import React, { useEffect, useState } from 'react'
import { QueryStatus, useQuery } from 'react-query'
import { Redirect, useLocation } from 'react-router'

import { ApiCall, getSlides, IGetSlidesProps } from '../../Api'
import SimpleImage from '../SimpleImage'
import SimpleText from '../SimpleText'
import SimpleVideo from '../SimpleVideo'
import WeatherSlide from '../WeatherSlide'

export const SlideShow = () => {
    const location = useLocation()

    const url = location.pathname

    const search = qs.parse(location.search, { ignoreQueryPrefix: true })

    const { data: slideData, status: slideStatus } = useQuery(
        [ApiCall.SLIDES, { url, presentationId: Number(search.presentationId) }],
        (_, props: IGetSlidesProps) => {
            return getSlides(props)
        },
        {
            refetchInterval: 30000
        }
    )

    const [active, setActive] = useState(0)
    const [loopcount, setLoopcount] = useState(0)

    const keys =
        slideData && Array.isArray(slideData.slides) ? slideData.slides.map((s) => s.key) : []

    useEffect(() => {
        if (keys) {
            const firstKey = keys[0]
            !active && setActive(firstKey)
        }
    }, [slideData, keys, active])

    const next = () => {
        let current = keys.indexOf(active)
        if (current === keys.length - 1) {
            current = -1
        }
        setActive(keys[current + 1])
    }

    const onFinish = () => {
        next()
    }

    useEffect(()=>{
        const timer = setTimeout(()=>{
            document.location.reload()
        }, 60*60*1000);

        return ()=>{
            clearTimeout(timer)
        }
    }, [])

    if (slideStatus !== 'success') return null
    if (!slideData) return null

    if (!slideData.slides.length) {
        return <Redirect to={'/'} />
    }

    return (
        <div className={'slides'}>
            {slideData.slides.map((slide, key) => {
                if (active !== slide.key) return null
                switch (slide.template) {
                    case 'video_slide':
                        return <SimpleVideo {...slide} slideId={slide.key} onFinish={onFinish} />
                    case 'image_slide':
                        return <SimpleImage {...slide} slideId={slide.key} onFinish={onFinish} />
                    case 'text_slide':
                        return <SimpleText {...slide} slideId={slide.key} onFinish={onFinish} />
                    case 'weather_slide':
                        return (
                            <WeatherSlide
                                {...slide}
                                coord={{
                                    lat: Number(slideData.branch.latitude),
                                    lng: Number(slideData.branch.longitude)
                                }}
                                region={slideData.branch.region}
                                slideId={slide.key}
                                onFinish={onFinish}
                            />
                        )
                    default:
                        return null
                }
            })}
        </div>
    )
}
