import { motion } from 'framer-motion'
import React, { useEffect } from 'react'
import { Logo, TextSlideContainer, TextSlide } from './styled'
import XcomLogo from './x-com-logo-black.svg';

//import { colors } from '../../styles/colors'

export const SimpleText = (props: {
    content: string,
    onFinish: () => void,
    timing?: number | null
    slideId: number
}) => {
    const { content, timing, onFinish, slideId } = props


    useEffect(() => {
        let timerId: NodeJS.Timeout | null
        if (timing) {
            timerId = setTimeout(onFinish, timing * 1000)
        }
        return () => {
            timerId && clearTimeout(timerId)
        }
    })

    return (
        <TextSlideContainer>
            <Logo><img src={XcomLogo} /></Logo>
            <TextSlide dangerouslySetInnerHTML={{ __html: content }}></TextSlide>
        </TextSlideContainer>
    )
}
